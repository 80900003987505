.inline__alert {
  display: flex;
  justify-content: space-between;
  margin: 24px -24px;
  padding: 12px; }
  @media (max-width: 952px) {
    .inline__alert {
      margin: 24px -12px;
      padding: 12px 24px; } }
  .inline__alert-section {
    margin-right: 0;
    margin-left: 0;
    border-radius: 4px; }
  .inline__alert-text {
    margin-bottom: 0; }
    .inline__alert-text__wrapper {
      display: flex; }
      .inline__alert-text__wrapper .toga-icon {
        margin-right: 16px;
        font-size: 24px; }
  .inline__alert-close-button {
    margin-top: -8px;
    margin-bottom: -8px; }
  .inline__alert svg {
    min-width: 24px;
    height: 24px;
    margin-right: 16px; }
  .inline__alert--info {
    background-color: #ECEFFB; }
    .inline__alert--info svg {
      fill: #5668C7; }
    .inline__alert--info .toga-icon {
      color: #5668C7; }
  .inline__alert--success {
    background-color: #F0F8F1; }
    .inline__alert--success a {
      color: #216E2F; }
      .inline__alert--success a:focus, .inline__alert--success a:hover {
        color: #278237; }
    .inline__alert--success svg {
      fill: #379A49; }
    .inline__alert--success .toga-icon {
      color: #379A49; }
  .inline__alert--warning {
    background-color: #FEEFE7; }
    .inline__alert--warning a {
      color: #AF4D1E; }
      .inline__alert--warning a:focus, .inline__alert--warning a:hover {
        color: #c55722; }
    .inline__alert--warning svg {
      fill: #FF7E40; }
    .inline__alert--warning .toga-icon {
      color: #FF7E40; }
  .inline__alert--danger {
    background-color: #FFF1F1; }
    .inline__alert--danger a {
      color: #A62626; }
      .inline__alert--danger a:focus, .inline__alert--danger a:hover {
        color: #bb2b2b; }
    .inline__alert--danger svg {
      fill: #D83232; }
    .inline__alert--danger .toga-icon {
      color: #D83232; }
  .inline__alert--first-element {
    margin-top: -24px;
    margin-right: -24px;
    margin-left: -24px;
    border-radius: 4px 4px 0 0; }
    @media (max-width: 952px) {
      .inline__alert--first-element {
        margin-top: -24px;
        margin-right: -12px;
        margin-left: -12px;
        padding: 12px 24px; } }
  .inline__alert--last-element {
    margin-right: -24px;
    margin-bottom: -32px;
    margin-left: -24px;
    border-radius: 0 0 4px 4px; }
    @media (max-width: 952px) {
      .inline__alert--last-element {
        margin-right: -12px;
        margin-bottom: -24px;
        margin-left: -12px;
        padding: 12px 24px; } }
