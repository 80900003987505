.sort-arrows {
  position: relative; }
  .sort-arrows .arrow-up,
  .sort-arrows .arrow-down {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 4px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    fill: #C28D00; }
    .sort-arrows .arrow-up.is-hovering-active,
    .sort-arrows .arrow-down.is-hovering-active {
      fill: #36394D; }
    .sort-arrows .arrow-up.is-hovering,
    .sort-arrows .arrow-down.is-hovering {
      opacity: 1;
      fill: #36394D; }
    .sort-arrows .arrow-up.active,
    .sort-arrows .arrow-down.active {
      opacity: 1; }
