.surface {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06); }
  .surface--raised {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25); }
  .surface--form {
    border-radius: 4px;
    background-color: #FBFBFB;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06); }
