.button {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  color: #36394D;
  font-family: "Barlow", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  line-height: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer; }
  .button:active, .button.pressed {
    outline: none; }
  .button:disabled {
    cursor: not-allowed;
    opacity: 0.45; }
  .button [class*='icon-'] {
    font-size: 1.5rem; }
  .button--primary {
    background-color: #100D59;
    color: #fff; }
    .button--primary svg {
      fill: #fff; }
    .button--primary:not([disabled]):focus, .button--primary:not([disabled]).focused, .button--primary:not([disabled]):hover, .button--primary:not([disabled]).hovered {
      background-color: #5668C7;
      color: #fff; }
      .button--primary:not([disabled]):focus svg, .button--primary:not([disabled]).focused svg, .button--primary:not([disabled]):hover svg, .button--primary:not([disabled]).hovered svg {
        fill: #fff; }
    .button--primary:not([disabled]).pressed, .button--primary:not([disabled]).active, .button--primary:not([disabled]):active {
      background-color: #3D47A3;
      color: #fff; }
      .button--primary:not([disabled]).pressed svg, .button--primary:not([disabled]).active svg, .button--primary:not([disabled]):active svg {
        fill: #fff; }
    .button--primary:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--primary:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--primary-dark {
    border-color: #fff;
    background-color: #fff;
    color: #100D59; }
    .button--primary-dark svg {
      fill: #100D59; }
    .button--primary-dark:not([disabled]):focus, .button--primary-dark:not([disabled]).focused, .button--primary-dark:not([disabled]):hover, .button--primary-dark:not([disabled]).hovered {
      border-color: #5668C7;
      background-color: #5668C7;
      color: #fff; }
      .button--primary-dark:not([disabled]):focus svg, .button--primary-dark:not([disabled]).focused svg, .button--primary-dark:not([disabled]):hover svg, .button--primary-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--primary-dark:not([disabled]).pressed, .button--primary-dark:not([disabled]).active, .button--primary-dark:not([disabled]):active {
      border-color: #3D47A3;
      background-color: #3D47A3;
      color: #fff; }
      .button--primary-dark:not([disabled]).pressed svg, .button--primary-dark:not([disabled]).active svg, .button--primary-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--primary-dark:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--primary-dark:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
  .button--danger {
    background-color: #D83232;
    color: #fff; }
    .button--danger svg {
      fill: #fff; }
    .button--danger:not([disabled]):focus, .button--danger:not([disabled]).focused, .button--danger:not([disabled]):hover, .button--danger:not([disabled]).hovered {
      background-color: #A62626;
      color: #fff; }
      .button--danger:not([disabled]):focus svg, .button--danger:not([disabled]).focused svg, .button--danger:not([disabled]):hover svg, .button--danger:not([disabled]).hovered svg {
        fill: #fff; }
    .button--danger:not([disabled]).pressed, .button--danger:not([disabled]).active, .button--danger:not([disabled]):active {
      background-color: #731A1A;
      color: #fff; }
      .button--danger:not([disabled]).pressed svg, .button--danger:not([disabled]).active svg, .button--danger:not([disabled]):active svg {
        fill: #fff; }
    .button--danger:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--danger:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--danger-dark {
    border-color: #fff;
    background-color: #fff;
    color: #D83232; }
    .button--danger-dark svg {
      fill: #D83232; }
    .button--danger-dark:not([disabled]):focus, .button--danger-dark:not([disabled]).focused, .button--danger-dark:not([disabled]):hover, .button--danger-dark:not([disabled]).hovered {
      border-color: #D83232;
      background-color: #D83232;
      color: #fff; }
      .button--danger-dark:not([disabled]):focus svg, .button--danger-dark:not([disabled]).focused svg, .button--danger-dark:not([disabled]):hover svg, .button--danger-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--danger-dark:not([disabled]).pressed, .button--danger-dark:not([disabled]).active, .button--danger-dark:not([disabled]):active {
      border-color: #A62626;
      background-color: #A62626;
      color: #fff; }
      .button--danger-dark:not([disabled]).pressed svg, .button--danger-dark:not([disabled]).active svg, .button--danger-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--danger-dark:disabled {
      color: rgba(216, 50, 50, 0.45); }
      .button--danger-dark:disabled svg {
        fill: rgba(216, 50, 50, 0.45); }
  .button--upgrade {
    background-color: #00A999;
    color: #fff; }
    .button--upgrade svg {
      fill: #fff; }
    .button--upgrade:not([disabled]):focus, .button--upgrade:not([disabled]).focused, .button--upgrade:not([disabled]):hover, .button--upgrade:not([disabled]).hovered {
      background-color: #006F63;
      color: #fff; }
      .button--upgrade:not([disabled]):focus svg, .button--upgrade:not([disabled]).focused svg, .button--upgrade:not([disabled]):hover svg, .button--upgrade:not([disabled]).hovered svg {
        fill: #fff; }
    .button--upgrade:not([disabled]).pressed, .button--upgrade:not([disabled]).active, .button--upgrade:not([disabled]):active {
      background-color: #122E2C;
      color: #fff; }
      .button--upgrade:not([disabled]).pressed svg, .button--upgrade:not([disabled]).active svg, .button--upgrade:not([disabled]):active svg {
        fill: #fff; }
    .button--upgrade:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--upgrade:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--upgrade-dark {
    border-color: #fff;
    background-color: #fff;
    color: #006F63; }
    .button--upgrade-dark svg {
      fill: #006F63; }
    .button--upgrade-dark:not([disabled]):focus, .button--upgrade-dark:not([disabled]).focused, .button--upgrade-dark:not([disabled]):hover, .button--upgrade-dark:not([disabled]).hovered {
      border-color: #00A999;
      background-color: #00A999;
      color: #fff; }
      .button--upgrade-dark:not([disabled]):focus svg, .button--upgrade-dark:not([disabled]).focused svg, .button--upgrade-dark:not([disabled]):hover svg, .button--upgrade-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--upgrade-dark:not([disabled]).pressed, .button--upgrade-dark:not([disabled]).active, .button--upgrade-dark:not([disabled]):active {
      border-color: #006F63;
      background-color: #006F63;
      color: #fff; }
      .button--upgrade-dark:not([disabled]).pressed svg, .button--upgrade-dark:not([disabled]).active svg, .button--upgrade-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--upgrade-dark:disabled {
      color: rgba(0, 169, 153, 0.45); }
      .button--upgrade-dark:disabled svg {
        fill: rgba(0, 169, 153, 0.45); }
  .button--secondary {
    border-color: #CACCD6;
    background-color: #fff;
    color: #100D59; }
    .button--secondary svg {
      fill: #100D59; }
    .button--secondary:not([disabled]):focus, .button--secondary:not([disabled]).focused, .button--secondary:not([disabled]):hover, .button--secondary:not([disabled]).hovered {
      border-color: #5668C7;
      background: #ECEFFB;
      color: #100D59; }
      .button--secondary:not([disabled]):focus svg, .button--secondary:not([disabled]).focused svg, .button--secondary:not([disabled]):hover svg, .button--secondary:not([disabled]).hovered svg {
        fill: #100D59; }
    .button--secondary:not([disabled]).pressed, .button--secondary:not([disabled]).active, .button--secondary:not([disabled]):active {
      border-color: #3D47A3;
      background-color: #5668C7;
      color: #fff; }
      .button--secondary:not([disabled]).pressed svg, .button--secondary:not([disabled]).active svg, .button--secondary:not([disabled]):active svg {
        fill: #fff; }
    .button--secondary:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--secondary:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
  .button--secondary-dark {
    border-color: #fff;
    background-color: transparent;
    color: #fff; }
    .button--secondary-dark svg {
      fill: #fff; }
    .button--secondary-dark:not([disabled]):focus, .button--secondary-dark:not([disabled]).focused, .button--secondary-dark:not([disabled]):hover, .button--secondary-dark:not([disabled]).hovered {
      border-color: #fff;
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff; }
      .button--secondary-dark:not([disabled]):focus svg, .button--secondary-dark:not([disabled]).focused svg, .button--secondary-dark:not([disabled]):hover svg, .button--secondary-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-dark:not([disabled]).pressed, .button--secondary-dark:not([disabled]).active, .button--secondary-dark:not([disabled]):active {
      border-color: #5668C7;
      background-color: #5668C7;
      color: #fff; }
      .button--secondary-dark:not([disabled]).pressed svg, .button--secondary-dark:not([disabled]).active svg, .button--secondary-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--secondary-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--secondary-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--secondary-danger {
    border-color: #FC6767;
    color: #D83232; }
    .button--secondary-danger:not([disabled]):focus, .button--secondary-danger:not([disabled]).focused, .button--secondary-danger:not([disabled]):hover, .button--secondary-danger:not([disabled]).hovered {
      border-color: #D83232;
      background-color: #FFF1F1;
      color: #D83232; }
      .button--secondary-danger:not([disabled]):focus svg, .button--secondary-danger:not([disabled]).focused svg, .button--secondary-danger:not([disabled]):hover svg, .button--secondary-danger:not([disabled]).hovered svg {
        fill: #D83232; }
    .button--secondary-danger:not([disabled]):active, .button--secondary-danger:not([disabled]).pressed {
      border-color: #A62626;
      background: #D83232;
      color: #fff; }
      .button--secondary-danger:not([disabled]):active svg, .button--secondary-danger:not([disabled]).pressed svg {
        fill: #fff; }
    .button--secondary-danger:disabled {
      color: rgba(216, 50, 50, 0.45); }
      .button--secondary-danger:disabled svg {
        fill: rgba(216, 50, 50, 0.45); }
  .button--secondary-danger-dark {
    border-color: #FC6767;
    background-color: transparent;
    color: #fff; }
    .button--secondary-danger-dark svg {
      fill: #fff; }
    .button--secondary-danger-dark:not([disabled]):focus, .button--secondary-danger-dark:not([disabled]).focused, .button--secondary-danger-dark:not([disabled]):hover, .button--secondary-danger-dark:not([disabled]).hovered {
      border-color: #FC6767;
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff; }
      .button--secondary-danger-dark:not([disabled]):focus svg, .button--secondary-danger-dark:not([disabled]).focused svg, .button--secondary-danger-dark:not([disabled]):hover svg, .button--secondary-danger-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-danger-dark:not([disabled]).pressed, .button--secondary-danger-dark:not([disabled]).active, .button--secondary-danger-dark:not([disabled]):active {
      border-color: #FC6767;
      background-color: #FC6767;
      color: #fff; }
      .button--secondary-danger-dark:not([disabled]).pressed svg, .button--secondary-danger-dark:not([disabled]).active svg, .button--secondary-danger-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--secondary-danger-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--secondary-danger-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--secondary-upgrade {
    border: 1px solid #006F63;
    color: #006F63; }
    .button--secondary-upgrade svg {
      fill: #006F63; }
    .button--secondary-upgrade:not([disabled]):focus, .button--secondary-upgrade:not([disabled]).focused, .button--secondary-upgrade:not([disabled]):hover, .button--secondary-upgrade:not([disabled]).hovered {
      border-color: #006F63;
      background-color: #EFFAF9;
      color: #006F63; }
      .button--secondary-upgrade:not([disabled]):focus svg, .button--secondary-upgrade:not([disabled]).focused svg, .button--secondary-upgrade:not([disabled]):hover svg, .button--secondary-upgrade:not([disabled]).hovered svg {
        fill: #006F63; }
    .button--secondary-upgrade:not([disabled]).pressed, .button--secondary-upgrade:not([disabled]).active, .button--secondary-upgrade:not([disabled]):active {
      border-color: #006F63;
      background-color: #006F63;
      color: #fff; }
      .button--secondary-upgrade:not([disabled]).pressed svg, .button--secondary-upgrade:not([disabled]).active svg, .button--secondary-upgrade:not([disabled]):active svg {
        fill: #fff; }
    .button--secondary-upgrade:disabled {
      color: rgba(0, 111, 99, 0.45); }
      .button--secondary-upgrade:disabled svg {
        fill: rgba(0, 111, 99, 0.45); }
  .button--secondary-upgrade-dark {
    border-color: #00A999;
    color: #fff; }
    .button--secondary-upgrade-dark svg {
      fill: #fff; }
    .button--secondary-upgrade-dark:not([disabled]):focus, .button--secondary-upgrade-dark:not([disabled]).focused, .button--secondary-upgrade-dark:not([disabled]):hover, .button--secondary-upgrade-dark:not([disabled]).hovered {
      border-color: #00A999;
      background-color: rgba(255, 255, 255, 0.15);
      color: #fff; }
      .button--secondary-upgrade-dark:not([disabled]):focus svg, .button--secondary-upgrade-dark:not([disabled]).focused svg, .button--secondary-upgrade-dark:not([disabled]):hover svg, .button--secondary-upgrade-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-upgrade-dark:not([disabled]).pressed, .button--secondary-upgrade-dark:not([disabled]).active, .button--secondary-upgrade-dark:not([disabled]):active {
      border-color: #00A999;
      background-color: #00A999;
      color: #fff; }
      .button--secondary-upgrade-dark:not([disabled]).pressed svg, .button--secondary-upgrade-dark:not([disabled]).active svg, .button--secondary-upgrade-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--secondary-upgrade-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--secondary-upgrade-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--secondary-success {
    border-color: #379A49;
    color: #379A49; }
    .button--secondary-success:not([disabled]):focus, .button--secondary-success:not([disabled]).focused, .button--secondary-success:not([disabled]):hover, .button--secondary-success:not([disabled]).hovered {
      border-color: #379A49;
      background-color: #216E2F;
      color: #fff; }
      .button--secondary-success:not([disabled]):focus svg, .button--secondary-success:not([disabled]).focused svg, .button--secondary-success:not([disabled]):hover svg, .button--secondary-success:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-success:not([disabled]):active, .button--secondary-success:not([disabled]).pressed {
      border-color: #216E2F;
      background: #216E2F;
      color: #fff; }
      .button--secondary-success:not([disabled]):active svg, .button--secondary-success:not([disabled]).pressed svg {
        fill: #fff; }
    .button--secondary-success:disabled {
      color: rgba(55, 154, 73, 0.45); }
      .button--secondary-success:disabled svg {
        fill: rgba(55, 154, 73, 0.45); }
  .button--secondary-warning {
    border-color: #FF7E40;
    color: #FF7E40; }
    .button--secondary-warning:not([disabled]):focus, .button--secondary-warning:not([disabled]).focused, .button--secondary-warning:not([disabled]):hover, .button--secondary-warning:not([disabled]).hovered {
      border-color: #AF4D1E;
      background-color: #AF4D1E;
      color: #fff; }
      .button--secondary-warning:not([disabled]):focus svg, .button--secondary-warning:not([disabled]).focused svg, .button--secondary-warning:not([disabled]):hover svg, .button--secondary-warning:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-warning:not([disabled]):active, .button--secondary-warning:not([disabled]).pressed {
      border-color: #AF4D1E;
      background: #AF4D1E;
      color: #fff; }
      .button--secondary-warning:not([disabled]):active svg, .button--secondary-warning:not([disabled]).pressed svg {
        fill: #fff; }
    .button--secondary-warning:disabled {
      color: rgba(255, 126, 64, 0.45); }
      .button--secondary-warning:disabled svg {
        fill: rgba(255, 126, 64, 0.45); }
  .button--secondary-info {
    border-color: #36394D;
    color: #36394D; }
    .button--secondary-info:not([disabled]):focus, .button--secondary-info:not([disabled]).focused, .button--secondary-info:not([disabled]):hover, .button--secondary-info:not([disabled]).hovered {
      border-color: #12131A;
      background-color: #12131A;
      color: #fff; }
      .button--secondary-info:not([disabled]):focus svg, .button--secondary-info:not([disabled]).focused svg, .button--secondary-info:not([disabled]):hover svg, .button--secondary-info:not([disabled]).hovered svg {
        fill: #fff; }
    .button--secondary-info:not([disabled]):active, .button--secondary-info:not([disabled]).pressed {
      border-color: #12131A;
      background: #12131A;
      color: #fff; }
      .button--secondary-info:not([disabled]):active svg, .button--secondary-info:not([disabled]).pressed svg {
        fill: #fff; }
    .button--secondary-info:disabled {
      color: rgba(54, 57, 77, 0.45); }
      .button--secondary-info:disabled svg {
        fill: rgba(54, 57, 77, 0.45); }
  .button--knockout {
    background-color: transparent;
    color: #100D59; }
    .button--knockout svg {
      fill: #100D59; }
    .button--knockout:not([disabled]):focus, .button--knockout:not([disabled]).focused, .button--knockout:not([disabled]):hover, .button--knockout:not([disabled]).hovered {
      border-color: #5668C7;
      color: #100D59; }
      .button--knockout:not([disabled]):focus svg, .button--knockout:not([disabled]).focused svg, .button--knockout:not([disabled]):hover svg, .button--knockout:not([disabled]).hovered svg {
        fill: #100D59; }
    .button--knockout:not([disabled]).pressed, .button--knockout:not([disabled]).active, .button--knockout:not([disabled]):active {
      border-color: #3D47A3;
      background-color: #ECEFFB;
      color: #100D59; }
      .button--knockout:not([disabled]).pressed svg, .button--knockout:not([disabled]).active svg, .button--knockout:not([disabled]):active svg {
        fill: #100D59; }
    .button--knockout:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--knockout:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
  .button--knockout-danger {
    color: #D83232; }
    .button--knockout-danger svg {
      fill: #D83232; }
    .button--knockout-danger:not([disabled]):focus, .button--knockout-danger:not([disabled]).focused, .button--knockout-danger:not([disabled]):hover, .button--knockout-danger:not([disabled]).hovered {
      border-color: #D83232;
      color: #D83232; }
      .button--knockout-danger:not([disabled]):focus svg, .button--knockout-danger:not([disabled]).focused svg, .button--knockout-danger:not([disabled]):hover svg, .button--knockout-danger:not([disabled]).hovered svg {
        fill: #D83232; }
    .button--knockout-danger:not([disabled]).pressed, .button--knockout-danger:not([disabled]).active, .button--knockout-danger:not([disabled]):active {
      border-color: #D83232;
      background-color: #D83232;
      color: #fff; }
      .button--knockout-danger:not([disabled]).pressed svg, .button--knockout-danger:not([disabled]).active svg, .button--knockout-danger:not([disabled]):active svg {
        color: #fff; }
    .button--knockout-danger:disabled {
      color: rgba(216, 50, 50, 0.45); }
      .button--knockout-danger:disabled svg {
        fill: rgba(216, 50, 50, 0.45); }
  .button--knockout-upgrade {
    color: #006F63; }
    .button--knockout-upgrade svg {
      color: #006F63; }
    .button--knockout-upgrade:not([disabled]):focus, .button--knockout-upgrade:not([disabled]).focused, .button--knockout-upgrade:not([disabled]):hover, .button--knockout-upgrade:not([disabled]).hovered {
      border-color: #006F63;
      color: #006F63; }
      .button--knockout-upgrade:not([disabled]):focus svg, .button--knockout-upgrade:not([disabled]).focused svg, .button--knockout-upgrade:not([disabled]):hover svg, .button--knockout-upgrade:not([disabled]).hovered svg {
        color: #006F63; }
    .button--knockout-upgrade:not([disabled]).pressed, .button--knockout-upgrade:not([disabled]).active, .button--knockout-upgrade:not([disabled]):active {
      border-color: #006F63;
      background-color: #006F63;
      color: #fff; }
      .button--knockout-upgrade:not([disabled]).pressed svg, .button--knockout-upgrade:not([disabled]).active svg, .button--knockout-upgrade:not([disabled]):active svg {
        color: #fff; }
    .button--knockout-upgrade:disabled {
      color: rgba(0, 169, 153, 0.45); }
      .button--knockout-upgrade:disabled svg {
        fill: rgba(0, 169, 153, 0.45); }
  .button--knockout-dark {
    background-color: transparent;
    color: #fff; }
    .button--knockout-dark svg {
      fill: #fff; }
    .button--knockout-dark:not([disabled]):focus, .button--knockout-dark:not([disabled]).focused, .button--knockout-dark:not([disabled]):hover, .button--knockout-dark:not([disabled]).hovered {
      border-color: #fff;
      color: #fff; }
      .button--knockout-dark:not([disabled]):focus svg, .button--knockout-dark:not([disabled]).focused svg, .button--knockout-dark:not([disabled]):hover svg, .button--knockout-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--knockout-dark:not([disabled]).pressed, .button--knockout-dark:not([disabled]).active, .button--knockout-dark:not([disabled]):active {
      background-color: #5668C7;
      color: #fff; }
      .button--knockout-dark:not([disabled]).pressed svg, .button--knockout-dark:not([disabled]).active svg, .button--knockout-dark:not([disabled]):active svg {
        fill: #fff; }
    .button--knockout-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--knockout-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--knockout-danger-dark {
    color: #fff; }
    .button--knockout-danger-dark svg {
      color: #fff; }
    .button--knockout-danger-dark:not([disabled]):focus, .button--knockout-danger-dark:not([disabled]).focused, .button--knockout-danger-dark:not([disabled]):hover, .button--knockout-danger-dark:not([disabled]).hovered {
      border-color: #A62626;
      color: #fff; }
      .button--knockout-danger-dark:not([disabled]):focus svg, .button--knockout-danger-dark:not([disabled]).focused svg, .button--knockout-danger-dark:not([disabled]):hover svg, .button--knockout-danger-dark:not([disabled]).hovered svg {
        color: #fff; }
    .button--knockout-danger-dark:not([disabled]).pressed, .button--knockout-danger-dark:not([disabled]).active, .button--knockout-danger-dark:not([disabled]):active {
      border-color: #A62626;
      background-color: #A62626;
      color: #fff; }
      .button--knockout-danger-dark:not([disabled]).pressed svg, .button--knockout-danger-dark:not([disabled]).active svg, .button--knockout-danger-dark:not([disabled]):active svg {
        color: #fff; }
    .button--knockout-danger-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--knockout-danger-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--knockout-upgrade-dark {
    color: #fff; }
    .button--knockout-upgrade-dark svg {
      color: #fff; }
    .button--knockout-upgrade-dark:not([disabled]):focus, .button--knockout-upgrade-dark:not([disabled]).focused, .button--knockout-upgrade-dark:not([disabled]):hover, .button--knockout-upgrade-dark:not([disabled]).hovered {
      border-color: #006F63;
      color: #fff; }
      .button--knockout-upgrade-dark:not([disabled]):focus svg, .button--knockout-upgrade-dark:not([disabled]).focused svg, .button--knockout-upgrade-dark:not([disabled]):hover svg, .button--knockout-upgrade-dark:not([disabled]).hovered svg {
        color: #fff; }
    .button--knockout-upgrade-dark:not([disabled]).pressed, .button--knockout-upgrade-dark:not([disabled]).active, .button--knockout-upgrade-dark:not([disabled]):active {
      border-color: #006F63;
      background-color: #006F63;
      color: #fff; }
      .button--knockout-upgrade-dark:not([disabled]).pressed svg, .button--knockout-upgrade-dark:not([disabled]).active svg, .button--knockout-upgrade-dark:not([disabled]):active svg {
        color: #fff; }
    .button--knockout-upgrade-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--knockout-upgrade-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--link {
    background-color: transparent;
    color: #5668C7;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none; }
    .button--link:not([disabled]):active, .button--link:not([disabled]).pressed {
      color: #100D59;
      box-shadow: none; }
    .button--link:not([disabled]):hover, .button--link:not([disabled]):hover:not(button), .button--link:not([disabled]).hovered, .button--link:not([disabled]):focus {
      text-decoration: none;
      color: #3D47A3; }
      .button--link:not([disabled]):hover:focus, .button--link:not([disabled]):hover:not(button):focus, .button--link:not([disabled]).hovered:focus, .button--link:not([disabled]):focus:focus {
        text-decoration: none; }
      .button--link:not([disabled]):hover:hover, .button--link:not([disabled]):hover:not(button):hover, .button--link:not([disabled]).hovered:hover, .button--link:not([disabled]):focus:hover {
        text-decoration: underline; }
      .button--link:not([disabled]):hover [class*='icon-']:before, .button--link:not([disabled]):hover:not(button) [class*='icon-']:before, .button--link:not([disabled]).hovered [class*='icon-']:before, .button--link:not([disabled]):focus [class*='icon-']:before {
        display: inline-block;
        text-decoration: none; }
    .button--link:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--link:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
  .button--link-dark {
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none; }
    .button--link-dark:not([disabled]):active, .button--link-dark:not([disabled]).pressed {
      color: #F2F3F5;
      box-shadow: none; }
    .button--link-dark:not([disabled]):focus, .button--link-dark:not([disabled]):hover, .button--link-dark:not([disabled]):hover:not(button), .button--link-dark:not([disabled]).hovered {
      text-decoration: none;
      color: #CACCD6; }
      .button--link-dark:not([disabled]):focus:focus, .button--link-dark:not([disabled]):hover:focus, .button--link-dark:not([disabled]):hover:not(button):focus, .button--link-dark:not([disabled]).hovered:focus {
        text-decoration: none; }
      .button--link-dark:not([disabled]):focus:hover, .button--link-dark:not([disabled]):hover:hover, .button--link-dark:not([disabled]):hover:not(button):hover, .button--link-dark:not([disabled]).hovered:hover {
        text-decoration: underline; }
      .button--link-dark:not([disabled]):focus [class*='icon-']:before, .button--link-dark:not([disabled]):hover [class*='icon-']:before, .button--link-dark:not([disabled]):hover:not(button) [class*='icon-']:before, .button--link-dark:not([disabled]).hovered [class*='icon-']:before {
        display: inline-block;
        text-decoration: none; }
    .button--link-dark:disabled {
      color: rgba(255, 255, 255, 0.45); }
      .button--link-dark:disabled svg {
        fill: rgba(255, 255, 255, 0.45); }
  .button--icon-left [class*='icon-'],
  .button--icon-left .spinner {
    margin-right: 8px;
    margin-left: -4px;
    font-size: 1.5rem;
    line-height: 24px; }
  .button--icon-right [class*='icon-'] {
    margin-right: -4px;
    margin-left: 4px;
    font-size: 1.5rem;
    line-height: 24px; }
  .button--empty-state-upsell {
    vertical-align: middle; }
  .button--icon-both [class*='icon-'] {
    font-size: 1.5rem;
    line-height: 24px; }
    .button--icon-both [class*='icon-']:first-child {
      margin-right: 8px;
      margin-left: -4px; }
    .button--icon-both [class*='icon-']:last-child {
      margin-right: -4px;
      margin-left: 4px; }
  .button--icon-only {
    width: 40px;
    height: 40px;
    padding: 0; }
    .button--icon-only [class*='icon-'] {
      margin: 0 auto;
      font-size: 1.5rem; }
  .button--icon-only-small {
    width: 32px;
    height: 32px;
    padding: 0; }
    .button--icon-only-small [class*='icon-'] {
      margin: 0 auto;
      font-size: 1.5rem; }
  .button--block {
    width: 100%; }
  .button--x-small {
    height: 24px;
    padding: 0 8px; }
  .button--small {
    height: 32px;
    padding: 0 16px; }
  .button--small-tight {
    height: 32px;
    padding: 0 8px; }
  .button--large {
    height: 48px;
    padding: 0 32px; }
  .button--naked {
    border-color: transparent; }
    .button--naked:hover .button--naked:active, .button--naked:focus {
      border-color: transparent; }
  .button--utility {
    background: transparent;
    color: #002C47; }
    .button--utility svg {
      fill: #002C47; }
    .button--utility:not([disabled]):hover, .button--utility:not([disabled]):focus, .button--utility:not([disabled]).hovered {
      background: rgba(0, 44, 71, 0.1);
      color: #002C47; }
      .button--utility:not([disabled]):hover svg, .button--utility:not([disabled]):focus svg, .button--utility:not([disabled]).hovered svg {
        fill: #002C47; }
    .button--utility:not([disabled]):active, .button--utility:not([disabled]).pressed {
      background: rgba(0, 44, 71, 0.05);
      color: #002C47; }
      .button--utility:not([disabled]):active svg, .button--utility:not([disabled]).pressed svg {
        fill: #002C47; }
    .button--utility:disabled {
      color: rgba(0, 44, 71, 0.45); }
      .button--utility:disabled svg {
        fill: rgba(0, 44, 71, 0.45); }
  .button--utility-dark {
    background: transparent;
    color: #CACCD6; }
    .button--utility-dark svg {
      fill: #CACCD6; }
    .button--utility-dark:not([disabled]):hover, .button--utility-dark:not([disabled]):focus, .button--utility-dark:not([disabled]).hovered {
      background: rgba(255, 255, 255, 0.3);
      color: #fff; }
      .button--utility-dark:not([disabled]):hover svg, .button--utility-dark:not([disabled]):focus svg, .button--utility-dark:not([disabled]).hovered svg {
        fill: #fff; }
    .button--utility-dark:not([disabled]):active, .button--utility-dark:not([disabled]).pressed {
      background: rgba(255, 255, 255, 0.15);
      color: #fff; }
      .button--utility-dark:not([disabled]):active svg, .button--utility-dark:not([disabled]).pressed svg {
        fill: #fff; }
    .button--utility-dark:disabled {
      color: rgba(202, 204, 214, 0.45); }
      .button--utility-dark:disabled svg {
        fill: rgba(202, 204, 214, 0.45); }
  .button--notification-warning:after {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 6px;
    height: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    background: #FFDE87;
    content: '';
    animation: warning 2s infinite ease; }

@keyframes warning {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 222, 135, 0.5); }
  30% {
    box-shadow: 0 0 0 4px rgba(255, 222, 135, 0.3); }
  100% {
    box-shadow: 0 0 0 4px rgba(255, 222, 135, 0); } }
    @media screen and (prefers-reduced-motion: reduce) {
      .button--notification-warning:after {
        animation: none; } }
  .button--notification-danger:after {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 6px;
    height: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    background: #FC6767;
    content: '';
    animation: danger 2s infinite ease; }

@keyframes danger {
  0% {
    box-shadow: 0 0 0 0 rgba(252, 103, 103, 0.5); }
  30% {
    box-shadow: 0 0 0 4px rgba(252, 103, 103, 0.3); }
  100% {
    box-shadow: 0 0 0 4px rgba(252, 103, 103, 0); } }
    @media screen and (prefers-reduced-motion: reduce) {
      .button--notification-danger:after {
        animation: none; } }
  .button--notification-success:after {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 6px;
    height: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    background: #72CA83;
    content: '';
    animation: success 2s infinite ease; }

@keyframes success {
  0% {
    box-shadow: 0 0 0 0 rgba(114, 202, 131, 0.5); }
  30% {
    box-shadow: 0 0 0 4px rgba(114, 202, 131, 0.3); }
  100% {
    box-shadow: 0 0 0 4px rgba(114, 202, 131, 0); } }
    @media screen and (prefers-reduced-motion: reduce) {
      .button--notification-success:after {
        animation: none; } }
  .button--accessible-link {
    background-color: transparent;
    color: #5668C7;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    height: initial;
    color: #5668C7;
    font-size: inherit;
    text-decoration: underline; }
    .button--accessible-link:not([disabled]):active, .button--accessible-link:not([disabled]).pressed {
      color: #100D59;
      box-shadow: none; }
    .button--accessible-link:not([disabled]):hover, .button--accessible-link:not([disabled]):hover:not(button), .button--accessible-link:not([disabled]).hovered, .button--accessible-link:not([disabled]):focus {
      text-decoration: none;
      color: #3D47A3; }
      .button--accessible-link:not([disabled]):hover:focus, .button--accessible-link:not([disabled]):hover:not(button):focus, .button--accessible-link:not([disabled]).hovered:focus, .button--accessible-link:not([disabled]):focus:focus {
        text-decoration: none; }
      .button--accessible-link:not([disabled]):hover:hover, .button--accessible-link:not([disabled]):hover:not(button):hover, .button--accessible-link:not([disabled]).hovered:hover, .button--accessible-link:not([disabled]):focus:hover {
        text-decoration: underline; }
      .button--accessible-link:not([disabled]):hover [class*='icon-']:before, .button--accessible-link:not([disabled]):hover:not(button) [class*='icon-']:before, .button--accessible-link:not([disabled]).hovered [class*='icon-']:before, .button--accessible-link:not([disabled]):focus [class*='icon-']:before {
        display: inline-block;
        text-decoration: none; }
    .button--accessible-link:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--accessible-link:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
    a.button--accessible-link,
    button.button--accessible-link {
      padding: 0;
      line-height: inherit; }
  .button--accessible-link-dark {
    background-color: transparent;
    color: #5668C7;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    height: initial;
    color: #5668C7;
    font-size: inherit;
    text-decoration: underline;
    color: #fff; }
    .button--accessible-link-dark:not([disabled]):active, .button--accessible-link-dark:not([disabled]).pressed {
      color: #100D59;
      box-shadow: none; }
    .button--accessible-link-dark:not([disabled]):hover, .button--accessible-link-dark:not([disabled]):hover:not(button), .button--accessible-link-dark:not([disabled]).hovered, .button--accessible-link-dark:not([disabled]):focus {
      text-decoration: none;
      color: #3D47A3; }
      .button--accessible-link-dark:not([disabled]):hover:focus, .button--accessible-link-dark:not([disabled]):hover:not(button):focus, .button--accessible-link-dark:not([disabled]).hovered:focus, .button--accessible-link-dark:not([disabled]):focus:focus {
        text-decoration: none; }
      .button--accessible-link-dark:not([disabled]):hover:hover, .button--accessible-link-dark:not([disabled]):hover:not(button):hover, .button--accessible-link-dark:not([disabled]).hovered:hover, .button--accessible-link-dark:not([disabled]):focus:hover {
        text-decoration: underline; }
      .button--accessible-link-dark:not([disabled]):hover [class*='icon-']:before, .button--accessible-link-dark:not([disabled]):hover:not(button) [class*='icon-']:before, .button--accessible-link-dark:not([disabled]).hovered [class*='icon-']:before, .button--accessible-link-dark:not([disabled]):focus [class*='icon-']:before {
        display: inline-block;
        text-decoration: none; }
    .button--accessible-link-dark:disabled {
      color: rgba(16, 13, 89, 0.45); }
      .button--accessible-link-dark:disabled svg {
        fill: rgba(16, 13, 89, 0.45); }
    a.button--accessible-link-dark,
    button.button--accessible-link-dark {
      padding: 0;
      line-height: inherit; }
    .button--accessible-link-dark:not([disabled]):hover, .button--accessible-link-dark:not([disabled]):focus, .button--accessible-link-dark:not([disabled]):active {
      color: #CACCD6; }

.button-group-isolated--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
  .button-group-isolated--left:hover, .button-group-isolated--left:focus {
    margin-right: -1px;
    border: 1px solid #5668C7;
    z-index: 1; }
    .button-group-isolated--left:hover:not(.button--primary), .button-group-isolated--left:focus:not(.button--primary) {
      margin-right: 0; }
    .button-group-isolated--left:hover:not(.button--icon-only), .button-group-isolated--left:focus:not(.button--icon-only) {
      padding-right: 17px; }

.button-group-isolated--right {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
  .button-group-isolated--right:hover:not([disabled]), .button-group-isolated--right:focus:not([disabled]) {
    width: 41px;
    margin-left: -1px;
    border-left: 1px solid #5668C7; }
  .button-group-isolated--right:hover:before, .button-group-isolated--right:focus:before {
    width: 0; }
  .button-group-isolated--right:hover.button--primary:hover:before, .button-group-isolated--right:hover.button--primary:focus:before, .button-group-isolated--right:focus.button--primary:hover:before, .button-group-isolated--right:focus.button--primary:focus:before {
    width: 0; }
  .button-group-isolated--right.button--primary:before {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0;
    width: 1px;
    background: #6F7282;
    content: ''; }

.dropdown {
  position: relative; }
  .dropdown hr {
    border-top-color: #CACCD6; }
  .dropdown__toggle {
    padding: 0 16px;
    text-align: center; }
    .dropdown__toggle.button--icon-only {
      padding: 0; }
    .dropdown__toggle .icon-caret {
      width: 24px;
      height: 24px;
      margin-right: -8px;
      margin-left: 16px;
      font-size: 1.5rem;
      line-height: 24px; }
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 160px;
    max-width: 320px;
    margin-top: 4px;
    padding: 8px 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    z-index: 10; }
    .dropdown-menu hr {
      margin-right: 16px;
      margin-left: 16px; }
  .dropdown-menu-right {
    right: 0;
    left: auto; }
  .dropdown.show .dropdown-menu {
    display: block; }
  .dropdown.button-group .button:first-child:not(.dropdown-toggle) {
    display: none; }
    @media (min-width: 664px) {
      .dropdown.button-group .button:first-child:not(.dropdown-toggle) {
        display: inline-flex; } }
  .dropdown.button-group .button.dropdown-toggle {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    @media screen and (min-width: 664px) {
      .dropdown.button-group .button.dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }
  .dropdown .dropdown-menu__caption {
    color: #6F7282;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
    padding: 8px 0 8px 24px; }
  .dropdown .dropdown-menu__item {
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
    color: #36394D;
    font-family: "Barlow", sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.03125rem;
    line-height: 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 8px 24px;
    border-radius: 0;
    text-align: left; }
    .dropdown .dropdown-menu__item:active, .dropdown .dropdown-menu__item.pressed {
      outline: none; }
    .dropdown .dropdown-menu__item:disabled {
      cursor: not-allowed;
      opacity: 0.45; }
    .dropdown .dropdown-menu__item [class*='icon-'] {
      font-size: 1.5rem; }
    .dropdown .dropdown-menu__item:active, .dropdown .dropdown-menu__item:focus {
      border-color: transparent;
      text-decoration: none;
      box-shadow: none; }
    .dropdown .dropdown-menu__item:hover {
      border-color: transparent !important;
      background-color: #F2F3F5;
      text-decoration: none; }
    .dropdown .dropdown-menu__item__description {
      width: 224px;
      margin-bottom: 0;
      padding: 4px 0 16px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;
      text-transform: none;
      white-space: normal; }
      @media screen and (max-width: 344px) {
        .dropdown .dropdown-menu__item__description {
          width: 156px; } }
  .dropdown .dropdown-menu__item--danger {
    color: #D83232 !important; }
    .dropdown .dropdown-menu__item--danger:focus, .dropdown .dropdown-menu__item--danger:hover {
      background-color: #FFF1F1;
      color: #D83232;
      text-decoration: none; }
  .dropdown .dropdown-menu__item--upgrade {
    height: min-content;
    font-size: 0.875rem; }
    .dropdown .dropdown-menu__item--upgrade-icon {
      margin-right: 12px;
      margin-left: -2px; }
      .dropdown .dropdown-menu__item--upgrade-icon:active {
        fill: #72D6CC; }
    .dropdown .dropdown-menu__item--upgrade:focus, .dropdown .dropdown-menu__item--upgrade:hover {
      background: #EFFAF9; }
    .dropdown .dropdown-menu__item--upgrade-button {
      display: flex;
      color: #72D6CC; }
  .dropdown .dropdown-menu__item--icon-right [class*="icon-"] {
    margin-right: -4px;
    margin-left: 4px;
    font-size: 1.5rem;
    line-height: 24px; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.action-button {
  display: none;
  height: 40px;
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #CACCD6;
  background: #fff; }
  .action-button.active {
    display: block; }
  .action-button:hover {
    z-index: 20; }

.inline-actions__container {
  display: flex;
  position: relative; }

.inline-actions__container-left {
  justify-content: flex-start; }

.inline-actions__container-right {
  justify-content: flex-end; }

.inline-actions__more-actions--button {
  border: 0; }
  .inline-actions__more-actions--button:active, .inline-actions__more-actions--button.pressed, .inline-actions__more-actions--button:focus, .inline-actions__more-actions--button.focused, .inline-actions__more-actions--button.active {
    box-shadow: none; }
  .inline-actions__more-actions--button__icon-left {
    margin-right: 8px;
    margin-left: -4px;
    font-size: 1.5rem;
    line-height: 24px; }
  .inline-actions__more-actions--button__icon-right {
    margin-right: -4px;
    margin-left: 4px;
    font-size: 1.5rem;
    line-height: 24px; }

.inline-actions__item .dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.inline-actions__item--upgrade {
  height: min-content; }
  .inline-actions__item--upgrade:hover {
    background: none; }

.inline-actions__separator {
  width: calc(100% - 32px);
  border: 0;
  border-top: 1px solid #CACCD6; }

.inline-actions--toggle {
  height: 40px;
  padding: 0 8px; }
  .inline-actions--toggle.has-main-action:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .inline-actions--toggle.compact-mode {
    border-radius: 4px;
    border-color: #CACCD6; }

.inline-actions--active-toggle {
  background: #fff; }
  .inline-actions--active-toggle.has-main-action {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
