.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0; }

.h1 {
  font-family: "Barlow", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 3rem; }
  @media (min-width: 1265px) {
    .h1 {
      font-size: 2.5rem;
      line-height: 3rem; } }

.h2 {
  font-family: "Barlow", sans-serif;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 2.5rem; }
  @media (min-width: 1265px) {
    .h2 {
      font-size: 2rem;
      line-height: 2.5rem; } }

.h3 {
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 2rem; }

.h4 {
  font-family: "Barlow", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.01875em;
  line-height: 1.625rem; }

.h5 {
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01875em;
  line-height: 1.5rem; }

.h6 {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase; }

.p {
  font-size: 1rem;
  line-height: 1.375rem; }

.caption {
  color: #6F7282;
  font-size: 0.875rem;
  line-height: 1rem; }

.kpi {
  font-family: "Barlow", sans-serif;
  font-weight: 100; }
