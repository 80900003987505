.main {
  margin: 0;
  padding: 0 8px 16px;
  transition: all 0.15s ease-out; }
  @media (min-width: 344px) {
    .main {
      padding: 0 12px 16px; } }
  @media (min-width: 952px) {
    .main {
      padding: 0 24px 32px; } }
  @media (min-width: 1265px) {
    .main {
      margin: calc(56px + 16px) 0 0;
      padding: 32px 24px 32px 32px; } }
  @media (min-width: 1265px) {
    .main--left {
      max-width: calc(1440px - 308px);
      margin: 0 0 0 308px; } }
  @media (min-width: 1265px) {
    .main--right {
      max-width: calc(1440px - 308px);
      margin: 0 308px 0 0; } }
  .main--full-width {
    max-width: none; }
  .main--mobile {
    padding: 56px 8px 0 16px; }
    @media (max-width: 343px) {
      .main--mobile {
        padding: 56px 12px 0 16px; } }
    @media (max-width: 951px) {
      .main--mobile {
        padding: 56px 24px 0 32px; } }
    .main--mobile .navbar {
      position: fixed !important;
      top: 56px;
      right: 0;
      left: 0;
      width: 100%;
      margin: 0; }
  @media (min-width: 664px) {
    .main--is-fullscreen {
      max-width: 100%;
      margin: 0;
      transition: all 0.15s ease-out; } }
