@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translate3d(100%, 0, 0); } }

.loading-row .loading-column {
  min-width: 150px; }
  .loading-row .loading-column .loading__container {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 16PX;
    border-radius: 4px;
    background: #F2F3F5; }
    .loading-row .loading-column .loading__container::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(242, 243, 245, 0.5) 0, rgba(202, 204, 214, 0.5) 50%, rgba(242, 243, 245, 0.5) 100%);
      content: '';
      animation: progress 1.5s ease-in-out infinite; }

.loading-row .first-column {
  min-width: 72px; }
