.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 8px 8px 8px 12px;
  border: 1px solid #CACCD6;
  border-radius: 3px;
  background: #fff;
  color: #36394D;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  box-sizing: border-box; }
  .container:not(.disabled):hover, .container:not(.disabled):focus, .container:not(.disabled).focus {
    border-color: #5668C7;
    outline: 0;
    background: #ECEFFB;
    color: #100D59;
    text-decoration: none; }
  .container.disabled {
    cursor: not-allowed;
    opacity: 0.45; }
  .container label {
    font-size: 0; }
    .container label::after {
      top: 5px;
      left: -4px; }

.child-container {
  max-width: calc(100% - 36px); }

.arrow {
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 16px; }
  .arrow.hidden {
    display: none; }
