.column-selector__wrapper {
  margin: 0 8px 0 0; }
  @media screen and (max-width: 952px) {
    .column-selector__wrapper {
      margin: 0; }
      .column-selector__wrapper svg {
        margin-right: 0 !important;
        margin-left: 0 !important; } }
  @media screen and (max-width: 952px) {
    .column-selector__wrapper .label__wrapper {
      display: none; } }

@media screen and (max-width: 952px) {
  .column-selector__toggle {
    width: 40px;
    height: 40px;
    padding: 0; } }

.modal__container {
  width: 100%;
  max-width: 912px; }
  @media screen and (max-width: 952px) {
    .modal__container {
      max-width: calc(100% - 24px); } }
  .modal__container .group__columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .modal__container .group__columns .group-wrapper {
      flex: 1;
      min-width: 160px;
      margin: 0 16px 24px 0; }
      .modal__container .group__columns .group-wrapper .group__item-list {
        margin: 0;
        padding: 0;
        list-style: none; }
        .modal__container .group__columns .group-wrapper .group__item-list .group__item {
          margin: 0 0 16px; }
