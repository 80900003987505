.divider {
  margin: 0;
  border-top-color: #57608C; }
  .divider--indented {
    margin-left: 24px; }
  .divider--vertical {
    flex-shrink: 0;
    width: 1px;
    height: inherit;
    border: 0;
    background-color: #57608C; }
