.empty-state {
  max-width: 608px;
  margin: 0 auto;
  padding: 16px;
  text-align: center; }
  @media (min-width: 1265px) {
    .empty-state {
      padding: 72px 16px 40px; } }
  .empty-state--card {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 40px;
    text-align: left;
    box-sizing: content-box; }
    .empty-state--card .section__line {
      margin: 24px -40px; }
    .empty-state--card.empty-state--info {
      padding-bottom: 16px;
      background-color: #ECEFFB;
      box-shadow: none; }
    .empty-state--card.empty-state--upsell {
      padding-bottom: 16px;
      background-color: #EFFAF9;
      box-shadow: none; }
  .empty-state--padded {
    padding-top: 16px;
    padding-bottom: 32px; }
    @media (min-width: 1265px) {
      .empty-state--padded {
        padding-top: 40px;
        padding-bottom: 56px; } }
  .empty-state__icon-container [class*="icon-"] {
    width: 56px;
    height: 56px; }
  .empty-state__icon {
    font-size: 3.5rem; }
  .empty-state__title {
    padding-top: 16px;
    padding-bottom: 24px;
    color: #36394D; }
  .empty-state__description {
    padding-bottom: 8px;
    color: #36394D; }
    @media (min-width: 1265px) {
      .empty-state__description {
        padding-bottom: 24px; } }
  .empty-state__actions {
    padding-top: 16px;
    padding-bottom: 8px; }
    .empty-state__actions > * {
      margin: 4px; }
    @media (min-width: 1265px) {
      .empty-state__actions {
        padding-bottom: 24px; }
        .empty-state__actions > * {
          margin: 0 8px; } }
    .empty-state__actions > :first-child {
      margin-left: 0; }
    .empty-state__actions > :last-child {
      margin-right: 0; }
  .empty-state__reference-link > * {
    font-size: 1rem; }
  .empty-state__reference-link .button {
    margin-left: -8px; }
  .empty-state__reference-link .button + .toga-icon-arrow-up-right,
  .empty-state__reference-link .reference-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -16px;
    color: #5668C7;
    font-size: 1rem;
    vertical-align: middle;
    fill: #5668C7; }
  .empty-state__reference-link:hover .toga-icon-arrow-up-right,
  .empty-state__reference-link:hover .reference-icon, .empty-state__reference-link:focus .toga-icon-arrow-up-right,
  .empty-state__reference-link:focus .reference-icon {
    color: #3D47A3;
    fill: #3D47A3; }
  .empty-state--dark {
    color: #fff; }
    .empty-state--dark .empty-state__title,
    .empty-state--dark .empty-state__description {
      color: #fff; }
    .empty-state--dark .empty-state__icon-container {
      fill: #fff; }
    .empty-state--dark .empty-state__reference-link .button + .toga-icon-arrow-up-right,
    .empty-state--dark .empty-state__reference-link .reference-icon {
      color: #fff;
      fill: #fff; }
    .empty-state--dark .empty-state__reference-link:focus .toga-icon-arrow-up-right,
    .empty-state--dark .empty-state__reference-link:focus .reference-icon, .empty-state--dark .empty-state__reference-link:hover .toga-icon-arrow-up-right,
    .empty-state--dark .empty-state__reference-link:hover .reference-icon {
      color: #CACCD6;
      fill: #CACCD6; }
  .empty-state a:not(.button):not(.dropdown-menu__item) {
    text-decoration: none; }
    .empty-state a:not(.button):not(.dropdown-menu__item):focus {
      text-decoration: none; }
    .empty-state a:not(.button):not(.dropdown-menu__item):hover {
      text-decoration: underline; }
  .empty-state a.button:focus, .empty-state a.button:hover {
    text-decoration: none; }
