.dropdown {
  position: relative;
  outline: none; }

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  min-width: 160px;
  margin-top: 4px;
  margin-left: 1px;
  padding: 0;
  border-radius: 4px;
  background: #fff;
  list-style: none;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  z-index: 2100;
  -webkit-tap-highlight-color: transparent; }
  .dropdown__menu.is-hidden {
    display: none; }
  .dropdown__menu.is-visible {
    display: block;
    opacity: 1; }
  .dropdown__menu.dropdown__menu-right {
    right: 0;
    left: initial; }

.dropdown__header {
  padding: 16px 16px 0;
  color: #6F7282;
  line-height: 24px; }

.dropdown__filter {
  flex-grow: 1;
  padding: 16px;
  border-bottom: 1px solid #F2F3F5; }

.dropdown__list {
  max-height: 336px;
  margin: 0;
  padding: 8px 0;
  list-style: none;
  overflow-y: auto;
  overscroll-behavior-y: contain; }
  .dropdown__list::-webkit-scrollbar {
    width: 8px; }
  .dropdown__list::-webkit-scrollbar-track {
    background: transparent; }
  .dropdown__list::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #979aa6; }
    .dropdown__list::-webkit-scrollbar-thumb:hover, .dropdown__list::-webkit-scrollbar-thumb:focus {
      background: #6F7282; }

.dropdown__list-item-header {
  padding: 16px 16px 0;
  color: #6F7282;
  font-weight: bold;
  text-transform: uppercase; }

.dropdown__list-item {
  display: flex;
  align-items: center;
  height: 40px;
  color: #6F7282;
  white-space: nowrap; }
  .dropdown__list-item > :first-child {
    flex-grow: 1;
    padding-right: 24px;
    padding-left: 24px;
    text-align: left; }
  .dropdown__list-item:hover, .dropdown__list-item:focus, .dropdown__list-item:active {
    background: #F2F3F5; }
  .dropdown__list-item > a,
  .dropdown__list-item > button:first-of-type {
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .dropdown__list-item a {
    text-decoration: none; }
  .dropdown__list-item button {
    margin: 0; }

.dropdown__list-item--actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px; }
  .dropdown__list-item--actions button i {
    font-size: 24px; }
    .dropdown__list-item--actions button i::before {
      color: #CACCD6 !important; }
  .dropdown__list-item--actions button:hover i::before, .dropdown__list-item--actions button:focus i::before {
    color: #12131A !important; }

.dropdown__list-item--actions > :first-child {
  flex-grow: 1;
  justify-content: left; }
