.select__container__menu {
  min-width: 160px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25); }
  .select__container__menu .select__item .select__item__button {
    width: 100%;
    border: 0;
    border-radius: 0;
    border-color: none;
    background: transparent;
    color: #36394D;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none; }
    .select__container__menu .select__item .select__item__button.pressed, .select__container__menu .select__item .select__item__button:active {
      background-color: #FFF8E6;
      box-shadow: none; }

.select__container__pre-options__separator {
  border: 0.5px solid #F2F3F5; }

.select__container .select-label {
  display: block;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden; }

.select__container .select-label--flex {
  display: flex; }
