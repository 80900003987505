.modal {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  width: 560px;
  max-width: calc(100% - 24px);
  max-height: 90vh !important;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 4px;
  outline: none;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto; }
  .modal .modal__header {
    display: block; }
    .modal .modal__header > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px; }
      .modal .modal__header > div.has-title {
        border-bottom: 1px solid #CACCD6; }
      .modal .modal__header > div h3 {
        margin: 0; }
      @media screen and (max-width: 664px) {
        .modal .modal__header > div {
          padding: 12px 16px; } }
    .modal .modal__header__close-button {
      margin-right: -8px;
      padding: 8px;
      border: 0;
      color: #36394D;
      font-size: 1.5rem; }
  .modal .modal__body {
    flex-grow: 1;
    padding: 24px 24px 32px;
    background: #FBFBFB;
    font-size: 1rem;
    overflow-y: auto; }
    .modal .modal__body.no-padding {
      padding: 0; }
    .modal .modal__body.no-title {
      padding: 24px; }
    @media screen and (max-width: 664px) {
      .modal .modal__body {
        padding: 24px 16px; } }
  .modal .modal__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #CACCD6;
    text-align: right; }
    .modal .modal__footer button:not(:first-child) {
      margin-left: 8px; }
    @media screen and (max-width: 664px) {
      .modal .modal__footer {
        padding: 12px 16px; } }

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2703; }
