.btn-loading__container {
  display: flex;
  align-items: center; }

.btn-loading__spinner {
  margin-right: 8px; }

.btn-loading__success-icon {
  margin-right: 8px;
  padding: 1px;
  border-radius: 100%;
  font-size: 14px; }

.primary--spinner,
.critical--spinner,
.upgrade--spinner {
  stroke: #fff; }

.secondary--spinner,
.knockout--spinner {
  stroke: #36394D; }

.primary--icon,
.critical--icon,
.upgrade--icon {
  background-color: #fff; }

.secondary--icon,
.knockout--icon {
  background-color: #36394D; }

.primary--icon {
  fill: #36394D; }

.secondary--icon {
  fill: #fff; }

.knockout--icon {
  fill: transparent; }

.critical--icon {
  fill: #D83232; }

.upgrade--icon {
  fill: #72D6CC; }
