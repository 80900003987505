.toast {
  display: flex;
  align-items: center;
  width: 484px;
  max-width: calc(100% - 16px);
  min-height: 48px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  opacity: 1;
  animation-name: slide;
  animation-duration: 300ms; }
  @media (prefers-reduced-motion: reduce) {
    .toast {
      animation: none; } }

@keyframes slide {
  0% {
    margin-bottom: -200px;
    opacity: 0; } }
  @media (max-width: 664px) {
    .toast {
      width: 100%;
      min-width: 304px; } }
  .toast--notice {
    background: #379A49; }
    .toast--notice .toast__button {
      border-color: #379A49;
      color: #379A49; }
      .toast--notice .toast__button:not([disabled]):focus, .toast--notice .toast__button:not([disabled]).focused, .toast--notice .toast__button:not([disabled]):hover, .toast--notice .toast__button:not([disabled]).hovered {
        border-color: #379A49;
        background-color: #216E2F;
        color: #fff; }
        .toast--notice .toast__button:not([disabled]):focus svg, .toast--notice .toast__button:not([disabled]).focused svg, .toast--notice .toast__button:not([disabled]):hover svg, .toast--notice .toast__button:not([disabled]).hovered svg {
          fill: #fff; }
      .toast--notice .toast__button:not([disabled]):active, .toast--notice .toast__button:not([disabled]).pressed {
        border-color: #216E2F;
        background: #216E2F;
        color: #fff; }
        .toast--notice .toast__button:not([disabled]):active svg, .toast--notice .toast__button:not([disabled]).pressed svg {
          fill: #fff; }
      .toast--notice .toast__button:disabled {
        color: rgba(55, 154, 73, 0.45); }
        .toast--notice .toast__button:disabled svg {
          fill: rgba(55, 154, 73, 0.45); }
  .toast--info {
    background: #36394D !important; }
    .toast--info .toast__button {
      border-color: #36394D;
      color: #36394D; }
      .toast--info .toast__button:not([disabled]):focus, .toast--info .toast__button:not([disabled]).focused, .toast--info .toast__button:not([disabled]):hover, .toast--info .toast__button:not([disabled]).hovered {
        border-color: #12131A;
        background-color: #12131A;
        color: #fff; }
        .toast--info .toast__button:not([disabled]):focus svg, .toast--info .toast__button:not([disabled]).focused svg, .toast--info .toast__button:not([disabled]):hover svg, .toast--info .toast__button:not([disabled]).hovered svg {
          fill: #fff; }
      .toast--info .toast__button:not([disabled]):active, .toast--info .toast__button:not([disabled]).pressed {
        border-color: #12131A;
        background: #12131A;
        color: #fff; }
        .toast--info .toast__button:not([disabled]):active svg, .toast--info .toast__button:not([disabled]).pressed svg {
          fill: #fff; }
      .toast--info .toast__button:disabled {
        color: rgba(54, 57, 77, 0.45); }
        .toast--info .toast__button:disabled svg {
          fill: rgba(54, 57, 77, 0.45); }
  .toast--alert {
    background: #D83232; }
    .toast--alert .toast__button {
      border-color: #D83232;
      color: #D83232; }
      .toast--alert .toast__button:not([disabled]):focus, .toast--alert .toast__button:not([disabled]).focused, .toast--alert .toast__button:not([disabled]):hover, .toast--alert .toast__button:not([disabled]).hovered {
        border-color: #A62626;
        background-color: #A62626;
        color: #fff; }
        .toast--alert .toast__button:not([disabled]):focus svg, .toast--alert .toast__button:not([disabled]).focused svg, .toast--alert .toast__button:not([disabled]):hover svg, .toast--alert .toast__button:not([disabled]).hovered svg {
          fill: #fff; }
      .toast--alert .toast__button:not([disabled]):active, .toast--alert .toast__button:not([disabled]).pressed {
        border-color: #731A1A;
        background: #731A1A;
        color: #fff; }
        .toast--alert .toast__button:not([disabled]):active svg, .toast--alert .toast__button:not([disabled]).pressed svg {
          fill: #fff; }
      .toast--alert .toast__button:disabled {
        color: rgba(216, 50, 50, 0.45); }
        .toast--alert .toast__button:disabled svg {
          fill: rgba(216, 50, 50, 0.45); }
  .toast--warning {
    background: #FF7E40; }
    .toast--warning .toast__button {
      border-color: #FF7E40;
      color: #FF7E40; }
      .toast--warning .toast__button:not([disabled]):focus, .toast--warning .toast__button:not([disabled]).focused, .toast--warning .toast__button:not([disabled]):hover, .toast--warning .toast__button:not([disabled]).hovered {
        border-color: #AF4D1E;
        background-color: #AF4D1E;
        color: #fff; }
        .toast--warning .toast__button:not([disabled]):focus svg, .toast--warning .toast__button:not([disabled]).focused svg, .toast--warning .toast__button:not([disabled]):hover svg, .toast--warning .toast__button:not([disabled]).hovered svg {
          fill: #fff; }
      .toast--warning .toast__button:not([disabled]):active, .toast--warning .toast__button:not([disabled]).pressed {
        border-color: #AF4D1E;
        background: #AF4D1E;
        color: #fff; }
        .toast--warning .toast__button:not([disabled]):active svg, .toast--warning .toast__button:not([disabled]).pressed svg {
          fill: #fff; }
      .toast--warning .toast__button:disabled {
        color: rgba(255, 126, 64, 0.45); }
        .toast--warning .toast__button:disabled svg {
          fill: rgba(255, 126, 64, 0.45); }
  .toast__icon {
    align-self: stretch;
    min-width: 40px;
    padding: 12px 8px;
    border-radius: 4px 0 0 4px;
    background: rgba(255, 255, 255, 0.15);
    color: #fff; }
    .toast__icon svg {
      fill: #fff; }
    .toast__icon .toga-icon {
      font-size: 24px; }
  .toast__dismiss {
    align-items: flex-start;
    align-self: flex-start;
    width: 24px;
    height: 24px;
    margin: 12px 12px 0 0;
    padding: 0;
    border: 0;
    color: #fff; }
    .toast__dismiss svg {
      fill: #fff; }
    .toast__dismiss:not([disabled]):hover, .toast__dismiss:not([disabled]):focus {
      color: #fff; }
      .toast__dismiss:not([disabled]):hover svg, .toast__dismiss:not([disabled]):focus svg {
        fill: #fff; }
    .toast__dismiss .toga-icon {
      font-size: 24px; }
  .toast__message {
    flex-grow: 1;
    padding: 12px 16px; }
    .toast__message--emphasis {
      font-style: italic;
      font-weight: 400; }
  .toast__button {
    display: block;
    flex-shrink: 0;
    margin: 12px 0 4px;
    background-color: #fff;
    font-size: 0.875rem;
    text-transform: uppercase; }
  .toast__container {
    display: flex;
    position: fixed;
    bottom: 16px;
    left: 16px;
    flex-direction: column-reverse;
    z-index: 2704; }
    @media (max-width: 952px) {
      .toast__container {
        left: 12px; } }
    @media (max-width: 344px) {
      .toast__container {
        left: 8px; } }
  .toast:first-child {
    margin-bottom: 0; }

.toast--primary {
  background: #5668C7; }

.toast--secondary {
  background: #6F7282; }

.toast--success {
  background: #379A49; }

.toast--info {
  background: #139BDE; }

.toast--warning {
  background: #FF7E40; }

.toast--danger {
  background: #D83232; }

.toast--light {
  background: #ECEFFB; }

.toast--dark {
  background: #100D59; }

.toast--upsell {
  background: #00A999; }
