.spinner {
  display: inline-block;
  animation-name: anim-spinner;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }
  .spinner .circle {
    overflow: hidden; }
  .spinner .circle-inner {
    width: 100%;
    height: 200%;
    transform: rotate(45deg);
    border-radius: 50%;
    animation-name: anim-circle-1;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
    animation-iteration-count: infinite;
    animation-direction: alternate; }
  .spinner .circle-2 {
    transform: rotate(180deg); }
    .spinner .circle-2 .circle-inner {
      animation-name: anim-circle-2; }

@keyframes anim-circle-1 {
  from {
    transform: rotate(60deg); }
  to {
    transform: rotate(205deg); } }

@keyframes anim-circle-2 {
  from {
    transform: rotate(47deg); }
  to {
    transform: rotate(-115deg); } }

@keyframes anim-spinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.spinner--default .circle {
  width: 16px;
  height: 8px; }

.spinner--default .circle-inner {
  border-top: 2px solid #FCCD56;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid #FCCD56; }

.spinner--small .circle {
  width: 24px;
  height: 12px; }

.spinner--small .circle-inner {
  border-top: 3px solid #FCCD56;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #FCCD56; }

.spinner--medium .circle {
  width: 32px;
  height: 16px; }

.spinner--medium .circle-inner {
  border-top: 4px solid #FCCD56;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #FCCD56; }

.spinner--large .circle {
  width: 40px;
  height: 20px; }

.spinner--large .circle-inner {
  border-top: 5px solid #FCCD56;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #FCCD56; }

.spinner--xlarge .circle {
  width: 48px;
  height: 24px; }

.spinner--xlarge .circle-inner {
  border-top: 6px solid #FCCD56;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #FCCD56; }
