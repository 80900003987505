.table-container {
  position: relative; }
  @media (prefers-reduced-motion: no-preference) {
    .table-container {
      transition: max-width .15s ease-out; } }
  @media screen and (max-width: 664px) {
    .table-container {
      width: 100%;
      -ms-overflow-style: -ms-autohiding-scrollbar; } }
  .table-container__selections-info {
    min-height: 40px;
    padding: 16px;
    background: #FFF8E6;
    line-height: 24px;
    text-align: center; }
    .table-container__selections-info a {
      color: #36394D;
      font-weight: 600; }
      .table-container__selections-info a:focus, .table-container__selections-info a:hover {
        color: #41445c; }
  .table-container__title {
    width: 100%;
    padding: 16px 24px; }
  .table-container__header {
    border-bottom: 1px solid #F2F3F5; }
    @media screen and (min-width: 664px) {
      .table-container__header {
        height: 74px; } }
  .table-container__header-headline {
    width: 100%;
    padding: 16px;
    color: #6F7282;
    text-align: center; }
    @media screen and (min-width: 664px) {
      .table-container__header-headline {
        padding: 24px;
        font-size: 0.875rem; } }
  .table-container__header, .table-container__footer {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media screen and (min-width: 664px) {
      .table-container__header, .table-container__footer {
        flex-direction: row; } }
  .table-container__header-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px; }
    @media screen and (min-width: 664px) {
      .table-container__header-settings {
        justify-content: flex-end;
        padding: 16px 24px; } }
  .table-container__header-info {
    margin-right: 24px;
    color: #6F7282; }
  .table-container__header-toolbar, .table-container__footer-info {
    flex-basis: 100%;
    padding: 16px;
    text-align: center; }
    @media screen and (min-width: 664px) {
      .table-container__header-toolbar, .table-container__footer-info {
        flex-basis: 50%;
        padding: 24px;
        line-height: 40px;
        text-align: left; } }
  .table-container__header-settings, .table-container__footer-pagination {
    flex-basis: 100%; }
    @media screen and (min-width: 664px) {
      .table-container__header-settings, .table-container__footer-pagination {
        flex-basis: 50%; } }
  @media screen and (min-width: 664px) {
    .table-container__footer-pagination .pagination {
      justify-content: flex-end; } }
  .table-container__content {
    min-height: .01%;
    overflow-x: auto; }
    .table-container__content-overflow {
      overflow: scroll; }
      @media screen and (min-width: 952px) {
        .table-container__content-overflow {
          overflow: visible; } }
  .table-container__footer {
    position: relative;
    margin-bottom: 16px; }
    .table-container__footer .pagination-info__text {
      display: flex;
      position: static;
      justify-content: center;
      margin-bottom: 32px;
      color: #CACCD6;
      font-size: 0.875rem; }
      @media screen and (min-width: 664px) {
        .table-container__footer .pagination-info__text {
          position: absolute;
          top: 0;
          right: 24px;
          align-items: center;
          height: 100%;
          margin-bottom: 0; } }
    .table-container__footer .pagination {
      width: 100%;
      padding: 16px; }
      @media screen and (min-width: 664px) {
        .table-container__footer .pagination {
          padding: 24px; } }
  .table-container--section {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 0; }
    .table-container--section .section__line {
      margin: 24px -0; }
  @media (min-width: 664px) {
    .table-container--fullscreen {
      max-width: 100% !important;
      transition: max-width .5s ease-in-out; } }

.table {
  width: 100%; }
  .table thead th {
    border-bottom: 1px solid #F2F3F5;
    font-weight: 600; }
  .table tbody tr {
    border-bottom: 1px solid #F2F3F5; }
    .table tbody tr:nth-child(even) {
      background: #FBFBFB; }
      .table tbody tr:nth-child(even) td.freeze-right {
        background: #FBFBFB; }
    .table tbody tr.locked:hover {
      background-color: rgba(239, 250, 249, 0.15); }
    .table tbody tr.selected {
      background: #FFF8E6; }
      .table tbody tr.selected td.freeze-right {
        background: #FFF8E6; }
    .table tbody tr:hover {
      background: #F2F3F5; }
      .table tbody tr:hover .freeze-right {
        background: #F2F3F5 !important; }
    .table tbody tr.dark {
      border-bottom: 1px solid #99A9FF;
      background: #100D59;
      color: #fff; }
      .table tbody tr.dark:nth-child(even) {
        background: #3D47A3; }
        .table tbody tr.dark:nth-child(even) td.freeze-right {
          background: #3D47A3; }
      .table tbody tr.dark.locked:hover {
        background-color: rgba(239, 250, 249, 0.15); }
      .table tbody tr.dark.selected {
        background: #FFF8E6; }
        .table tbody tr.dark.selected td.freeze-right {
          background: #FFF8E6; }
      .table tbody tr.dark:hover {
        background: #99A9FF; }
        .table tbody tr.dark:hover .freeze-right {
          background: #99A9FF !important; }
  .table td,
  .table th {
    height: 64px;
    padding: 0 16px;
    white-space: nowrap; }
    .table td:first-of-type,
    .table th:first-of-type {
      padding-left: 24px; }
    .table td:last-child,
    .table th:last-child {
      padding-right: 24px; }
    .table td.normal-white-space,
    .table th.normal-white-space {
      white-space: normal; }
    .table td.fit-width,
    .table th.fit-width {
      width: 1%;
      text-align: right;
      white-space: nowrap; }
    .table td.freeze-right,
    .table th.freeze-right {
      display: flex;
      position: absolute;
      top: auto;
      right: 0;
      align-items: center;
      padding: 11px 16px;
      background-color: #fff;
      box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1); }
    .table td.inline-edit,
    .table th.inline-edit {
      border-left: 4px solid #FCCD56; }
    .table td .dropdown-container,
    .table th .dropdown-container {
      position: relative; }
  .table a:not(.button):not(.dropdown-menu__item) {
    text-decoration: none; }
    .table a:not(.button):not(.dropdown-menu__item):focus {
      text-decoration: none; }
    .table a:not(.button):not(.dropdown-menu__item):hover {
      text-decoration: underline; }
  .table.table-minimal thead th {
    border-bottom: 1px solid #CACCD6; }
  .table.table-minimal tbody tr:not(:last-child) {
    border-bottom: 1px solid #CACCD6; }
  .table.table-minimal tbody tr:first-child {
    border-top: 1px solid #CACCD6; }
  .table.table-minimal tbody tr:nth-child(even) {
    background: transparent; }
  .table.table-minimal tbody tr.dark:nth-child(even), .table.table-minimal tbody tr.dark:hover {
    background: #100D59; }

.table-freeze-right table {
  border-collapse: separate;
  border-spacing: 0; }
  .table-freeze-right table th.static {
    padding: 20px 16px !important;
    background-color: #fff; }
  .table-freeze-right table td.static {
    text-align: right; }

@media screen and (min-width: 1265px) {
  .table-container {
    max-width: 1080px; } }

.table-container .table-container__header {
  flex-flow: row;
  justify-content: space-between; }
  @media screen and (max-width: 664px) {
    .table-container .table-container__header {
      flex-flow: row wrap; } }

.table-container .table-container__header-toolbar {
  display: flex;
  flex: 1;
  flex-basis: auto;
  align-items: center;
  line-height: 0; }
  .table-container .table-container__header-toolbar .header-toolbar__checkbox--wrapper {
    margin: 0 8px 0 -8px;
    border: 1px solid #CACCD6;
    border-radius: 4px; }
    @media screen and (max-width: 664px) {
      .table-container .table-container__header-toolbar .header-toolbar__checkbox--wrapper {
        margin: 0 8px 0 0; } }
  @media screen and (max-width: 664px) {
    .table-container .table-container__header-toolbar {
      width: 50%; } }

.table-container .header-toolbar__checkbox--wrapper {
  padding: 12px; }

.table-container .table-container__header-settings {
  flex-basis: auto; }
  @media screen and (max-width: 664px) {
    .table-container .table-container__header-settings {
      flex-flow: row wrap;
      justify-content: flex-end;
      width: 50%; } }

.table-container .table-container__header-pagination-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 16px 0; }
  .table-container .table-container__header-pagination-info .table-container__header-info,
  .table-container .table-container__header-pagination-info .table-container__footer-info {
    color: #6F7282; }
  .table-container .table-container__header-pagination-info .table-container__header-info {
    flex-shrink: 0; }
    .table-container .table-container__header-pagination-info .table-container__header-info--border {
      padding-left: 24px;
      border-left: 1px solid #CACCD6; }
      @media screen and (max-width: 664px) {
        .table-container .table-container__header-pagination-info .table-container__header-info--border {
          margin-left: 0;
          padding-left: 0;
          border-left: 0; } }
  @media screen and (max-width: 664px) {
    .table-container .table-container__header-pagination-info {
      justify-content: space-between;
      width: 100%;
      padding: 16px;
      border-top: 1px solid #F2F3F5; } }

.table-container .table-container__selections-info .select-all-in-dataset__button,
.table-container .table-container__selections-info .clear-selection-in-dataset__button {
  height: auto;
  margin: 0 0 0 8px;
  padding: 0;
  border: 0;
  color: #36394D;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline; }
  @media screen and (max-width: 664px) {
    .table-container .table-container__selections-info .select-all-in-dataset__button,
    .table-container .table-container__selections-info .clear-selection-in-dataset__button {
      width: 100%;
      margin: 0; } }

.table-container .table-container__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 0 0 24px;
  border-top: 1px solid #F2F3F5;
  background: #fff; }
  .table-container .table-container__footer .table-container__footer-info {
    position: absolute;
    left: 0; }
    @media screen and (max-width: 664px) {
      .table-container .table-container__footer .table-container__footer-info {
        display: none; } }

.table-container .table th:first-of-type,
.table-container .table td:first-of-type {
  padding-left: 16px; }

.table-container .table th {
  justify-content: flex-start;
  cursor: pointer; }

.table-container .table .table-head {
  display: flex;
  align-items: center;
  padding: 0; }

.table-container .frozen-column {
  display: flex;
  justify-content: flex-end;
  min-width: 138px; }

.table-container .frozen-column-compact {
  width: 71px;
  min-width: 71px; }

.table-container .freeze-column__last-column__spacer {
  display: block;
  width: 85px;
  height: 1px; }

.table-container .frozen-column-compact__spacer {
  width: 24px; }
