.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #fff; }
  .pagination ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none; }
    .pagination ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center; }
      .pagination ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #000;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1rem;
        text-decoration: none;
        cursor: pointer; }
        .pagination ul li a .toga-icon {
          font-size: 1.25rem; }
      .pagination ul li.active {
        box-shadow: inset 0 -2px 0 #5668C7; }
        .pagination ul li.active a {
          color: #5668C7; }
      .pagination ul li.inactive a {
        cursor: default;
        opacity: 0.3; }
      .pagination ul li.inactive:hover, .pagination ul li.inactive:focus {
        box-shadow: none; }
      .pagination ul li.pagination__index {
        display: none; }
        @media screen and (min-width: 664px) {
          .pagination ul li.pagination__index {
            display: flex; } }
      .pagination ul li:hover, .pagination ul li:focus {
        box-shadow: inset 0 -2px 0 #000; }
        .pagination ul li:hover a, .pagination ul li:focus a {
          color: #000; }

.pagination__index a:hover {
  text-decoration: none; }

.pagination--compact-mode {
  height: auto; }
  .pagination--compact-mode .compact-mode--spacer {
    width: 16px; }
